import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_section_layout_content = _resolveComponent("section-layout-content")!
  const _directive_hoist = _resolveDirective("hoist")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_section_layout_content, _mergeProps(_ctx.content, {
    onActionCreate: _ctx.create,
    backPageTitle: "History",
    style: {"overflow":"hidden"}
  }), {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.record.fileName), 1),
      _createElementVNode("a", {
        class: "text-blue-600",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.download(_ctx.record)))
      }, "Download"),
      _withDirectives(_createVNode(_component_el_table, {
        ref: "historyDetailTable",
        data: _ctx.documents,
        style: {"width":"100%"},
        lazy: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            property: "orderName",
            label: "Order Reference ID",
            "min-width": 40
          }),
          _createVNode(_component_el_table_column, {
            property: "numberRecords",
            label: "Status",
            "min-width": 20
          }, {
            default: _withCtx((scope) => [
              (scope.row.status === 'success')
                ? (_openBlock(), _createBlock(_component_el_tag, {
                    key: 0,
                    type: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Success " + _toDisplayString(scope.row.successOrder), 1)
                    ]),
                    _: 2
                  }, 1024))
                : (_openBlock(), _createBlock(_component_el_tag, {
                    key: 1,
                    type: "error"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Error " + _toDisplayString(scope.row.failedOrder), 1)
                    ]),
                    _: 2
                  }, 1024))
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            property: "note",
            label: "Detail",
            "min-width": 30
          }, {
            default: _withCtx((scope) => [
              _withDirectives(_createElementVNode("template", {
                innerHTML: _ctx.detectNote(scope.row.note)
              }, null, 8, _hoisted_1), [
                [_directive_hoist]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "lazy"]), [
        [_directive_loading, _ctx.loading]
      ]),
      _createVNode(_component_pagination, {
        total: _ctx.total,
        pagination: _ctx.query.page,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.query.page) = $event)),
        limit: _ctx.query.limit,
        "onUpdate:limit": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.query.limit) = $event))
      }, null, 8, ["total", "pagination", "limit"])
    ]),
    _: 1
  }, 16, ["onActionCreate"]))
}