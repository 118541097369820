
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useFetchData, useNotification } from '@/composables'
import Pagination from '@/components/common/Pagination.vue'
import { useRoute } from 'vue-router'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { getDetailImportOrder, detectNote } from '@/utils/orderUtils'

export default defineComponent({
  components: {
    sectionLayoutContent,
    Pagination,
  },
  setup() {
    const breadcrumbs = [
      {
        text: `Orders`,
        link: 'seller.orders',
        params: null,
      },
      {
        text: `Import history`,
        link: 'seller.orders.import.histories',
        params: null,
      },
      {
        text: `Detail`,
        link: '',
        params: null,
      },
    ]
    const title = 'Orders'
    let loading = ref(false)
    const { params } = useRoute()
    const { getImportDetail } = useOrderRepositories()
    const { error } = useNotification()
    let record = ref({})
    const getDetail = async () => {
      loading.value = true
      const { data, status } = await getImportDetail(params.id as string)
      if (status === 200) {
        record.value = data as any
      } else {
        error(data.message)
      }
      loading.value = false
    }
    getDetail()

    const query = reactive({ page: 1, limit: 10 })

    const detailOrderUrl = `orders/import/${params.id}/orders`
    const { documents, fetchData, isLoading, total } = useFetchData(
      detailOrderUrl,
      query
    )
    loading = isLoading
    fetchData()

    const onFilterCallback = (data: any) => {
      params.search = data?.search
    }

    const download = (row: any) => {
      try {
        downloadFile('import-file.csv', row.fileUrl)
      } catch (err) {
        console.log(err)
      }
    }

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    watch(query, fetchData)

    const content = computed(() => {
      return { breadcrumbs, title }
    })

    const forceUpdate = async () => {
      loading.value = true
      await fetchData()
      loading.value = false
    }

    return {
      params,
      content,
      loading,
      documents,
      record,
      forceUpdate,
      onFilterCallback,
      getDetailImportOrder,
      download,
      detectNote,
      total,
      query,
    }
  },
})
