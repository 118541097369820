import { AuthorityEnum } from './userUtils'
import {
  ORDER_STATUS,
  HIRE_DESIGN_STATUS,
  ADDRESS_STATUS,
  UserTypeEnum,
  DESIGN_STATUS,
} from './constants'

export const getOrderStatusColor = (orderStatus: string) => {
  let color = 'primary'
  switch (orderStatus) {
    case ORDER_STATUS.IN_REVIEW:
    case ORDER_STATUS.HOLD: {
      color = 'warning'
      break
    }
    case ORDER_STATUS.APPROVED:
    case ORDER_STATUS.IN_PRODUCTION:
    case ORDER_STATUS.IN_TRANSIT:
    case ORDER_STATUS.FULFILLED:
    case ORDER_STATUS.PARTIALLY_FULFILLED: {
      color = 'success'
      break
    }
    case ORDER_STATUS.CANCEL:
    case ORDER_STATUS.CANCEL_SHIPMENT:
    case ORDER_STATUS.REJECTED: {
      color = 'danger'
      break
    }
    default: {
      color = 'primary'
      break
    }
  }
  return color
}

export const getAddressStatusColor = (status: number) => {
  let color = 'primary'
  switch (status) {
    case ADDRESS_STATUS.US_VALID: {
      color = 'success'
      break
    }
    case ADDRESS_STATUS.US_INVALID: {
      color = 'info'
      break
    }
    case ADDRESS_STATUS.VERIFIED: {
      color = 'warning'
      break
    }
    default: {
      color = 'primary'
      break
    }
  }
  return color
}

export const getDetailImportOrder = (note: string) => {
  let detail = note
  switch (note) {
    case 'not_shipping_country': {
      detail = 'Area can not shipping'
      break
    }
    default: {
      break
    }
  }
  return detail
}

export const detectNote = (note: string) => {
  if (note) {
    if (note.includes('not_shipping_country')) {
      note = note.replaceAll('not_shipping_country', 'Area can not shipping')
    }
    const notes = note.split('\n').filter(item => item !== '')
    let content = ''
    notes.forEach(item => (content += `<p>${item}</p>`))
    return content
  }
  return note
}

// Order ở các trạng thái: UNFULFILLED, HOLD, IN_REVIEW thì mới cho edit design
export const validateEditArtworkCheck = (status: string) => {
  switch (status) {
    case ORDER_STATUS.UNFULFILLED:
    case ORDER_STATUS.HOLD:
    case ORDER_STATUS.IN_REVIEW:
    case ORDER_STATUS.APPROVED:
    case ORDER_STATUS.IN_PRODUCTION:
    case ORDER_STATUS.IN_TRANSIT:
      return true
    default:
      return false
  }
}
export const validateEditableByDesignStatus = (
  designStatus: HIRE_DESIGN_STATUS
) => {
  if (
    designStatus === HIRE_DESIGN_STATUS.ASSIGN ||
    designStatus === HIRE_DESIGN_STATUS.REJECTED
  ) {
    return true
  }
  return false
}

export const countDesignQtyOrderItem = (item: any) => {
  let count = 0
  if (item) {
    if (item.artwork1?.length) {
      count++
    }
    if (item.artwork2?.length) {
      count++
    }
    if (item.artwork3?.length) {
      count++
    }
    if (item.artwork4?.length) {
      count++
    }
  }
  return count
}

export const getTrackingOfOrder = (order: any) => {
  const trackingNumbers = new Set<string>()
  if (order && order.items) {
    const items = order.items as any[]
    for (const item of items) {
      if (item.trackingNumbers?.length > 0) {
        for (const trackingNum of item.trackingNumbers) {
          trackingNumbers.add(trackingNum)
        }
      } else if (item.trackingNumber?.length > 0) {
        trackingNumbers.add(item.trackingNumber)
      }
    }
  }
  return trackingNumbers
}

export const validateOrderAction = () => {
  const validateApproved = (status: string) => {
    return status === ORDER_STATUS.IN_REVIEW
  }

  const validateReject = (status: string) => {
    return (
      status === ORDER_STATUS.IN_REVIEW ||
      status === ORDER_STATUS.APPROVED ||
      status === ORDER_STATUS.IN_TRANSIT ||
      status === ORDER_STATUS.IN_PRODUCTION
    )
  }

  const validateHold = (status: string) => {
    return (
      status === ORDER_STATUS.UNFULFILLED
      // || status === ORDER_STATUS.IN_REVIEW
    )
  }

  const validateUnHold = (status: string) => {
    return status === ORDER_STATUS.HOLD
  }

  const validateCancelShip = (status: string) => {
    return (
      status === ORDER_STATUS.IN_TRANSIT ||
      status === ORDER_STATUS.IN_PRODUCTION
    )
  }

  const validatePickup = (status: string) => {
    return (
      status === ORDER_STATUS.IN_TRANSIT ||
      status === ORDER_STATUS.IN_PRODUCTION
    )
  }

  const validateSellerCancel = (order: any) => {
    const { status, source } = order
    const checkStatus =
      status === ORDER_STATUS.HOLD ||
      status === ORDER_STATUS.UNFULFILLED ||
      status === ORDER_STATUS.IN_REVIEW ||
      status === ORDER_STATUS.APPROVED
    const checkSource = source ? source === 'csv' : true
    return checkStatus && checkSource
  }

  const validateAgencyCancel = (status: string) => {
    return (
      status === ORDER_STATUS.UNFULFILLED ||
      status === ORDER_STATUS.HOLD ||
      status === ORDER_STATUS.IN_REVIEW ||
      status === ORDER_STATUS.APPROVED
    )
  }

  const validateAgencyUnHold = (status: string) => {
    return status === ORDER_STATUS.HOLD
  }

  const validateSellerHireDesign = (status: string) => {
    return status === ORDER_STATUS.UNFULFILLED
  }

  const validateVerifyAddress = (status: string) => {
    return status === ORDER_STATUS.UNFULFILLED || status === ORDER_STATUS.HOLD
  }

  const validateUpdateSyncedTrackToStore = (status: string) => {
    return (
      status === ORDER_STATUS.IN_TRANSIT ||
      status === ORDER_STATUS.FULFILLED ||
      status === ORDER_STATUS.PARTIALLY_FULFILLED
    )
  }

  const validateActionWithStatus = (status: string) => {
    return (
      status === ORDER_STATUS.UNFULFILLED ||
      status === ORDER_STATUS.IN_TRANSIT ||
      status === ORDER_STATUS.IN_PRODUCTION ||
      status === ORDER_STATUS.APPROVED ||
      status === ORDER_STATUS.IN_REVIEW
    )
  }

  const validateActionWithDesignStatus = (status: string) => {
    return (
      status &&
      (status === HIRE_DESIGN_STATUS.ASSIGN ||
        status === HIRE_DESIGN_STATUS.REJECTED)
    )
  }

  const validateOrderActionSeller = (status: string) => {
    return (
      status === ORDER_STATUS.UNFULFILLED ||
      status === ORDER_STATUS.HOLD ||
      status === ORDER_STATUS.IN_REVIEW ||
      status === ORDER_STATUS.APPROVED ||
      status === ORDER_STATUS.IN_PRODUCTION ||
      status === ORDER_STATUS.IN_TRANSIT
    )
  }

  enum ALLOW_STATUS_WITH_ACTION {
    SELLER_HOLD = 'Unfulfilled',
    SELLER_UNHOLD = 'Hold',
    SELLER_CANCEL = 'Unfulfilled, Hold, InReview, Approved',
    SELLER_VERIFY_ADDRESS = 'Unfulfilled, Hold',
    SELLER_SYNCED_TRACK_TO_STORE = 'InTransit, Fulfilled, Partially Fulfilled',
    SELLER_HIRE_DESIGN = 'Unfulfilled',
    AGENCY_APPROVE = 'InReview',
    AGENCY_REJECT = 'InReview, Approved, InProduction',
    AGENCY_CANCEL_SHIP = 'InProduction', // InTransit?
  }

  return {
    validateApproved,
    validatePickup,
    validateReject,
    validateHold,
    validateUnHold,
    validateCancelShip,
    validateSellerCancel,
    validateAgencyCancel,
    validateAgencyUnHold,
    validateSellerHireDesign,
    validateVerifyAddress,
    validateActionWithStatus,
    validateActionWithDesignStatus,
    validateOrderActionSeller,
    ALLOW_STATUS_WITH_ACTION,
    validateUpdateSyncedTrackToStore,
  }
}

const convertToTitleCase = (str: string) => {
  const words: string[] = str.split('_')
  const capitalizedWords: string[] = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  )
  return capitalizedWords.join(' ')
}

export const getWarningContent = (key: string) => {
  if (!key) return ''
  switch (key) {
    // case 'not_enough_design':
    //   return 'Not enough design'
    // case 'not_found_catalog':
    //   return 'Not found catalog'
    // case 'not_enough_cash':
    //   return 'Not enough cash'
    case 'not_shipping_country':
      return 'Area can not shipping'
    case 'not_found_catalog_variant':
      return 'Not found catalog'
    case 'bc_or_shipping_zero':
      return 'Base-cost or shipping not found'
    default:
      return convertToTitleCase(key)
  }
}

// cac action luu lich su thay doi order
export const CHANGE_ORDER_HISTORY = () => {
  enum ROLE {
    AGENCY = 'AGENCY',
    SELLER = 'SELLER',
    DESIGNER = 'DESIGNER',
  }
  enum ACTION_NAME {
    CHANGE_CUSTOMER_INFO = 'CHANGE_CUSTOMER_INFO',
    CHANGE_DESIGN = 'CHANGE_DESIGN',
    UPLOAD_DESIGN = 'UPLOAD_DESIGN',
    CHANGE_PREVIEW = 'CHANGE_PREVIEW',
    VERIFY_ADDRESS = 'VERIFY_ADDRESS',
    CHANGE_CATALOG_CODE = 'CHANGE_CATALOG_CODE',
    UPDATE_CATALOG_CODE_BY_FILE = 'UPDATE_CATALOG_CODE_BY_FILE',
    CHANGE_DESIGN_SERVICE_STATUS = 'CHANGE_DESIGN_SERVICE_STATUS',
    CHANGE_DESIGN_COST = 'CHANGE_DESIGN_COST',
  }
  enum DATA_CHANGED_NAME {
    CUSTOMER = 'Customer',
  }
  return { ACTION_NAME, DATA_CHANGED_NAME, ROLE }
}

export const validateOrderStatusReCalculate = (status: string) => {
  return (
    status === ORDER_STATUS.IN_REVIEW ||
    status === ORDER_STATUS.APPROVED ||
    status === ORDER_STATUS.IN_PRODUCTION ||
    status === ORDER_STATUS.IN_TRANSIT ||
    status === ORDER_STATUS.FULFILLED ||
    status === ORDER_STATUS.PARTIALLY_FULFILLED
  )
}

export const changeHistory = (order: any, role: string, data: any) => {
  if (!order?.changeHistory) {
    order.changeHistory = {}
  }
  const changeHistoryData = order.changeHistory
  let agencyChangeData
  if (role === 'AGENCY') {
    if (!changeHistoryData?.agency) {
      changeHistoryData.agency = []
    }
    agencyChangeData = changeHistoryData.agency
  }
  if (role === 'SELLER') {
    if (!changeHistoryData?.seller) {
      changeHistoryData.seller = []
    }
    agencyChangeData = changeHistoryData.seller
  }

  agencyChangeData.push(data)

  return order
}

export const changeMetadata = (order: any, metadata: any) => {
  if (!order?.metadata) {
    order.metadata = {}
  }
  const changeMetadata = order.metadata
  if (metadata.hire_design_status && metadata.hire_design_status !== '')
    changeMetadata.hire_design_status = metadata.hire_design_status
  if (metadata.items_issues) {
    changeMetadata.items_issues = metadata.items_issues
  }
  if (metadata.designFeeValue && metadata.designFeeValue > 0) {
    changeMetadata.designFeeValue = metadata.designFeeValue
  }
  order.metadata = changeMetadata

  return order
}

// kiem tra dieu kien cho phep export order manufacturing
export const isManagerOrAccounting = (user: any): boolean => {
  if (!user || typeof user === 'string') return false
  const { userType, isSubUser, authority } = user
  return (
    userType === UserTypeEnum.Agency &&
    (!isSubUser ||
      authority === AuthorityEnum.Accounting ||
      authority === AuthorityEnum.Staff ||
      authority === AuthorityEnum.OrderExporter)
  )
}

export const isAgencyManager = (user: any): boolean => {
  if (!user || typeof user === 'string') return false
  return user.userType === UserTypeEnum.Agency && !user.isSubUser
}

export const isDesignerManager = (user: any): boolean => {
  if (!user || typeof user === 'string') return false
  const { userType, isSubUser, authority } = user
  return (
    userType === UserTypeEnum.Agency &&
    (!isSubUser ||
      authority === AuthorityEnum.Accounting ||
      authority === AuthorityEnum.Staff)
  )
}

export const isDesignerAssignment = (user: any): boolean => {
  if (!user || typeof user === 'string') return false
  return (
    user.userType === UserTypeEnum.Agency &&
    user.authority === AuthorityEnum.Designer
  )
}
